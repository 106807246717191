import { IntlShape } from "react-intl";
import { MainData, SECTION_ID } from "../data/types";
import { RoofType } from "../reducers/appData";

export const findProduct = ({
  data,
  roofType,
  intl,
}: {
  data: MainData;
  intl: IntlShape;
  roofType: RoofType;
}) => {
  let productKey;

  if (roofType === RoofType.FLAT) {
    productKey = Object.keys(data).find(
      (e: string) =>
        data[parseInt(e, 10)]?.id === SECTION_ID.VAPOR_CONTROL_LAYER
    );
  } else {
    productKey = Object.keys(data).find((e: string) =>
      [
        SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
        SECTION_ID.PITCHED_ROOF_WHICH_UNDERLAY_OUTSIDE,
      ].includes(data[parseInt(e, 10)]?.id as SECTION_ID)
    );
  }

  const productType = data[
    productKey ? parseInt(productKey, 10) : 0
  ].nodes.find((n) => n.selected)?.title;

  let _product;
  let txt;

  if (productType?.includes("Majrex")) {
    txt = "Majrex 200";
  } else if (productType?.includes("Majpell")) {
    txt = "Majpell 5 / Majpell 5 R";
  } else if (productType?.includes("Majcoat")) {
    txt = "Majcoat 200 (SOB)";
  } else if (productType === "Underlay membrane sd value ≤ 0.1 m") {
    txt = "Underlay membrane sd value ≤ 0.1 m";
  } else {
    txt = productType || "Majpell 5 / Majpell 5 R";
  }
  _product = intl.formatMessage({
    id: txt,
    defaultMessage: txt,
  });
  console.log(_product);
  return _product;
};
