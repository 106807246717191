import { useAppDispatch, useAppSelector } from "../../hooks";
import styled from "styled-components";
import { Step } from "../Step";
import { SuccessfulParameters } from "../SuccessfulParameters";
import { useEffect, useCallback } from "react";
import classNames from "classnames";
import {
  debugState,
  ErrorType,
  setBackendValues,
  setParametersFetched,
  setParametersFetchError,
  setParametersFetching,
  setSectionParametersFetched,
} from "../../reducers/appData";
import { getParameters } from "../../api";
import { DATA_LOADING_LEVEL, MEDIA_SMALL } from "../../constants";
import React from "react";
import { API } from "../../data/types";

const StepsContainer = styled.div`
  padding: 0 38px;
  margin-bottom: 36px;
  ${MEDIA_SMALL} {
    margin-top: 36px;
    &.stickyStepContainer {
      margin-top: 286px;
    }
  }
`;

const Steps: React.FC<{ sticky: boolean }> = ({ sticky }) => {
  const data = useAppSelector((state) => state.appData.data);
  const currentLevel = useAppSelector((state) => state.appData.currentLevel);
  const lastLevelReached = useAppSelector(
    (state) => state.appData.lastLevelReached
  );
  const parametersFetching = useAppSelector(
    (state) => state.appData.parametersFetching
  );
  const parametersFetched = useAppSelector(
    (state) => state.appData.parametersFetched
  );
  const dispatch = useAppDispatch();

  const fetchParams = useCallback(
    async (api?: API) => {
      if (!api) return;
      dispatch(setParametersFetching(true));
      dispatch(setParametersFetchError(undefined));
      try {
        const res = await getParameters(data, api);
        if ((res as any).data?.error) {
          dispatch(setParametersFetchError(ErrorType.FAILED_REQUEST));
        } else {
          let allFalse = true;
          const fetchedData = (res as any).data?.data as {
            [key: string]: {
              [key: string]: boolean;
            };
          };
          for (let key of Object.keys(fetchedData)) {
            for (let val of Object.values(fetchedData[key])) {
              if (val !== false) {
                allFalse = false;
                break;
              }
            }
          }
          if (allFalse === true) {
            dispatch(setParametersFetchError(ErrorType.NO_VALID_PARAMETERS));
            dispatch(setBackendValues({ data: fetchedData, api }));
          } else {
            dispatch(setParametersFetchError(undefined));
            dispatch(setBackendValues({ data: fetchedData, api }));
          }
        }
        dispatch(setParametersFetching(false));
        // data[currentLevel].paramsFetched = true;
        dispatch(
          setSectionParametersFetched({ level: currentLevel, fetched: true })
        );
        dispatch(setParametersFetched(true));
      } catch (e) {
        dispatch(setParametersFetchError(ErrorType.FAILED_REQUEST));
        console.error("Error: ", e);
        dispatch(setParametersFetching(false));
        // data[currentLevel].paramsFetched = true;
        dispatch(
          setSectionParametersFetched({ level: currentLevel, fetched: true })
        );
        dispatch(setParametersFetched(true));
      }
    },
    [data, dispatch]
  );

  useEffect(() => {
    if (parametersFetching) return;
    if (data[currentLevel]?.api && !data[currentLevel].paramsFetched) {
      fetchParams(data[currentLevel].api);
    }
    if (
      currentLevel < DATA_LOADING_LEVEL &&
      data[currentLevel]?.paramsFetched !== undefined
    ) {
      // data[currentLevel].paramsFetched = true;
      dispatch(
        setSectionParametersFetched({ level: currentLevel, fetched: true })
      );
      dispatch(setParametersFetched(false));
    }
  }, [
    currentLevel,
    data,
    dispatch,
    parametersFetched,
    parametersFetching,
    fetchParams,
  ]);
  return (
    <>
      <StepsContainer className={classNames({ stickyStepContainer: sticky })}>
        {data.map((_d, _level) => {
          // const level = parseInt(_level, 10);
          const { title, nodes, divider, nr, warning, coverImage } = _d;
          return (
            <Step
              key={`level_${_level}`}
              level={_level}
              fetchParams={_d?.api ? () => fetchParams(_d.api) : undefined}
              // hidden={_level > currentLevel}
              hidden={false}
              title={title}
              coverImage={coverImage}
              divider={divider}
              warning={warning ?? []}
              theNr={nr}
              nodes={nodes}
            />
          );
        })}
      </StepsContainer>
      <SuccessfulParameters lastLevelReached={lastLevelReached} />
    </>
  );
};

export default Steps;
