import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { useIntl } from "react-intl";
import dompurify from "dompurify";
import { valuesToTranslate } from "../../constants";
import { DataNode } from "../../data/types";

const sanitizer = dompurify.sanitize;

const CustomFormSelect = styled(Select)`
  height: 40px;
  max-width: 146px;
  margin-bottom: 16px;
`;

const Progress = styled(CircularProgress)`
  position: relative;
  top: 4px;
  left: 6px;
`;

const CustomMenuItem = styled(MenuItem)`
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column;
`;

export interface SelectComponentProps {
  title: string;
  value: string;
  id: string;
  childNodes?: DataNode[];
  onSelect: (id: string, value: string) => void;
  isLoading: boolean;
  disabled: boolean;
  shouldShowDisabledMessage?: boolean;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  title,
  id,
  childNodes,
  value,
  onSelect,
  isLoading,
  disabled,
  shouldShowDisabledMessage = false,
}) => {
  const intl = useIntl();
  return (
    <FormControl fullWidth>
      <FormLabel id={`select-${id}`} disabled={isLoading || disabled}>
        <p
          style={{ margin: 0 }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              intl.formatMessage({ id: title, defaultMessage: title })
            ),
          }}
        ></p>
        {isLoading && <Progress color="primary" size={16} />}
      </FormLabel>
      <CustomFormSelect
        id={`select-${id}`}
        value={value ?? ""}
        labelId={`select-${id}`}
        disabled={isLoading || disabled}
        onChange={(e: any) => {
          onSelect(id, e.target.value as string);
        }}
      >
        {childNodes?.map((entry, num) => (
          <CustomMenuItem key={num} value={entry.id} disabled={entry.disabled}>
            {valuesToTranslate.includes(entry.title)
              ? intl.formatMessage({
                  id: entry.title,
                  defaultMessage: entry.title,
                })
              : entry.title}
            {entry.disabled && shouldShowDisabledMessage && (
              <span style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "This configuration is not available",
                  defaultMessage: "This configuration is not available",
                })}
              </span>
            )}
          </CustomMenuItem>
        ))}
      </CustomFormSelect>
    </FormControl>
  );
};

export default SelectComponent;
