import { Fragment } from "react";
import { WarningContainer } from "../../styledComponents";
import {
  addCol,
  isNumeric,
  nodeValue,
  sanitizeWarningMessage,
  showCheckedRadio,
} from "../../utils";
import { SupportedLanguage } from "../../App";
import { IntlShape } from "react-intl";
import dompurify from "dompurify";
import { CheckedRadio } from "../../reducers/appData";
import { useAppSelector } from "../../hooks";
import styled from "styled-components";
import { valuesToTranslate } from "../../constants";
import { DataNode, MainData } from "../../data/types";

interface FormattedNodeProps {
  i: number;
  theKey: string;
  intl: IntlShape;
  lang: SupportedLanguage;
  data: MainData;
}

const NodeWarningContainer = styled(WarningContainer)`
  // background-position: 20px 20px;
`;

const sanitizer = dompurify.sanitize;

const NodeValue: React.FC<{
  intl: IntlShape;
  node: DataNode;
  level: number;
  checkedRadios: CheckedRadio[];
}> = ({ intl, node, level, checkedRadios }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: sanitizer(
        isNumeric(nodeValue(node).value) &&
          !valuesToTranslate.includes(nodeValue(node).value)
          ? nodeValue(node).value
          : `${intl.formatMessage({
              id: nodeValue(node).value,
              defaultMessage: nodeValue(node).value,
            })} ${showCheckedRadio(checkedRadios, node.id, level)}`
      ),
    }}
  />
);

const FormattedNode: React.FC<FormattedNodeProps> = ({
  intl,
  lang,
  theKey,
  i,
  data,
}) => {
  const checkedRadios = useAppSelector((state) => state.appData.checkedRadios);
  const level = parseInt(theKey, 10);
  const levelData = data[level];
  const selectedNode = levelData.nodes.find((n) => n.selected);
  return (
    <Fragment key={i}>
      {selectedNode &&
        levelData.title &&
        !levelData.mustSelectAll &&
        nodeValue(selectedNode).value && (
          <div className="info-field">
            <div
              className="info-key"
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  addCol(
                    intl.formatMessage({
                      id: levelData.title,
                      defaultMessage: levelData.title,
                    })
                  )
                ),
              }}
            />
            <div className="info-value">
              <NodeValue
                intl={intl}
                node={selectedNode}
                level={level}
                checkedRadios={checkedRadios}
              />
            </div>
          </div>
        )}
      {levelData.mustSelectAll &&
        levelData.nodes
          .filter((n) => n.selected)
          .map(
            (nn, i) =>
              nodeValue(nn).title &&
              nodeValue(nn).value && (
                <div key={i} className="info-field">
                  <div
                    className="info-key"
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(
                        addCol(
                          intl.formatMessage({
                            id: nodeValue(nn).title,
                            defaultMessage: nodeValue(nn).title,
                          })
                        )
                      ),
                    }}
                  />
                  <div className="info-value">
                    <NodeValue
                      intl={intl}
                      node={nn}
                      level={level}
                      checkedRadios={checkedRadios}
                    />
                  </div>
                </div>
              )
          )}
      {levelData.warning &&
        levelData.warning.map((wMsg, i) => (
          <NodeWarningContainer
            key={i}
            className={`warning-message ${
              levelData.nodes?.find(
                (n) =>
                  (n.id === wMsg.rule || n.value === wMsg.rule) && n.selected
              )
                ? "open"
                : "hidden"
            }`}
            dangerouslySetInnerHTML={{
              __html: sanitizeWarningMessage(
                wMsg.message,
                lang,
                intl,
                wMsg.pdf || undefined
              ),
            }}
          />
        ))}
    </Fragment>
  );
};

export default FormattedNode;
