import { Button, FormControl, FormLabel, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import styled from "styled-components";
import { MEDIA_SMALLER, MEDIA_SUPER_SMALL, TEXT_COLOR } from "../../constants";
import { IntlShape, useIntl } from "react-intl";
import { setFormFieldValue, setFormTimestamp } from "../../reducers/appData";
import { useNavigate } from "react-router-dom";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldError,
} from "react-hook-form";
import { ForwardArrow } from "../ForwardArrow";
import validator from "validator";
import { CountrySelector } from "../CountrySelector";
import { SigaFormTextField } from "../../styledComponents";
import { FormField } from "../../data/types";

const SigaFormControl = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const SIGAForm = styled.form`
  margin-top: 48px;
`;

const SubmitButtonContainer = styled.div`
  max-width: 312px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 64px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  border-radius: 40px !important;
  text-transform: initial !important;
  font-weight: 300 !important;
  height: 45px;
`;

const SigaFormInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  flex: 0 0 50%;
  ${MEDIA_SMALLER} {
    flex: 0 0 100%;
  }
`;

const SectionTitle = styled.h3`
  flex: 0 0 100%;
  color: ${TEXT_COLOR};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
`;

const SigaFormLabel = styled(FormLabel)`
  flex: 0 0 250px;
  color: ${TEXT_COLOR} !important;
  margin-bottom: 8px;
`;

const validatePhoneNumber = (phoneNumber: string) => {
  // Regular expression to match phone numbers starting with + or 00 followed by digits
  const phoneRegex = /^(00|\+)\d{10,15}$/;
  return phoneRegex.test(phoneNumber);
};

const ContactForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.appData.formData);
  const handleFormSubmit = () => {
    // setOpen(true);
    const d = new Date();
    dispatch(setFormTimestamp(d.getTime()));
    navigate("./step-2");
  };
  const handleOnChange = (id: string, value: string) => {
    dispatch(setFormFieldValue({ id, value }));
  };

  const defaultValues: { [key: string]: string } = {};
  formData.fields.forEach((f) => {
    defaultValues[`form-field-${f.id}`] = f.value || "";
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ [key: string]: string }>({
    defaultValues,
    mode: "onBlur",
  });

  const handleError = (intl: IntlShape, error?: FieldError) => {
    if (!error) {
      return "";
    }
    if (error.type === "required") {
      return intl.formatMessage({
        id: "This field is required",
        defaultMessage: "This field is required",
      });
    } else if (error.type === "email") {
      return intl.formatMessage({
        id: "Please provide a valid email address",
        defaultMessage: "Please provide a valid email address",
      });
    } else if (error.type === "tel") {
      return intl.formatMessage({
        id: "Please provide a valid telephone number",
        defaultMessage: "Please provide a valid telephone number",
      });
    } else {
      return intl.formatMessage({
        id: "Validation error",
        defaultMessage: "Validation error",
      });
    }
  };

  const validate = (type: string) => {
    const validators: { [key: string]: (v: any) => boolean } = {};
    if (type === "email") {
      validators.email = (value: any) => validator.isEmail(value);
    }
    if (type === "tel") {
      validators.tel = (value: any) => validatePhoneNumber(value);
    }

    return validators;
  };

  const onSubmit: SubmitHandler<{ [key: string]: string }> = () =>
    handleFormSubmit();

  const submitDisabled =
    Object.keys(errors).length > 0 ||
    formData.fields.some((e) => e.value.length === 0 && e.required);

  return (
    <SIGAForm onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        {formData.sections.map((s, idx) => (
          <div key={idx}>
            {s.sectionTitle && (
              <SectionTitle>
                {intl.formatMessage({
                  id: s.sectionTitle,
                  defaultMessage: s.sectionTitle,
                })}
              </SectionTitle>
            )}
            <SigaFormControl key={idx}>
              {formData.fields.slice(s.from, s.to).map((f: FormField, i) => (
                <SigaFormInputWrapper key={i}>
                  <SigaFormLabel required={f.required}>
                    {intl.formatMessage({
                      id: f.label,
                      defaultMessage: f.label,
                    })}
                  </SigaFormLabel>
                  <Controller
                    name={`form-field-${f.id}`}
                    control={control}
                    defaultValue={f.value ?? ""}
                    rules={{
                      required: f.required,
                      value: f.value ?? "",
                      onChange: (ev) => {
                        handleOnChange(f.id, ev.target.value);
                      },
                      validate: { ...validate(f.type) },
                    }}
                    render={({ field }) =>
                      f.id.indexOf("country") !== -1 ? (
                        <CountrySelector
                          id={f.id}
                          disabled={f.disabled}
                          field={field}
                          value={f.value}
                          label={f.label}
                          placeholder={intl.formatMessage({
                            id: f.placeholder,
                            defaultMessage: f.placeholder,
                          })}
                          required={f.required}
                          error={!!errors?.[`form-field-${f.id}`]}
                          helperText={handleError(
                            intl,
                            errors[`form-field-${f.id}`]
                          )}
                          key={i}
                        />
                      ) : (
                        <SigaFormTextField
                          {...field}
                          placeholder={intl.formatMessage({
                            id: f.placeholder,
                            defaultMessage: f.placeholder,
                          })}
                          error={!!errors?.[`form-field-${f.id}`]}
                          helperText={handleError(
                            intl,
                            errors[`form-field-${f.id}`]
                          )}
                          type={f.type}
                          hiddenLabel
                        />
                      )
                    }
                  />
                </SigaFormInputWrapper>
              ))}
            </SigaFormControl>
          </div>
        ))}
      </FormControl>
      <SubmitButtonContainer>
        <SubmitButton
          variant="contained"
          disableElevation
          type="submit"
          disabled={submitDisabled}
        >
          {intl.formatMessage({
            id: formData.submitButton.title,
            defaultMessage: formData.submitButton.title,
          })}
          <ForwardArrow disabled={submitDisabled} />
        </SubmitButton>
      </SubmitButtonContainer>
    </SIGAForm>
  );
};

export default ContactForm;
