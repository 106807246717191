import {
  angleNodes,
  notSupportedMessage,
  customPdfForm,
  detectFreeMessage,
} from "../constants";
import { API, BUTTON_TYPE, Condition, DataSection, SECTION_ID } from "../types";
import sigaPitchedRoof from "../../assets/sigaPitchedRoof.svg";

import pitched_roof_open from "../../assets/images/pitched_roof_open.svg";
import pitched_roof_tight from "../../assets/images/pitched_roof_tight.svg";

import pitched_roof_open_png from "../../assets/images/pitched_roof_open.png";
import pitched_roof_tight_png from "../../assets/images/pitched_roof_tight.png";

export const pitchedRoofSections: DataSection[] = [
  {
    id: SECTION_ID.PITCHED_ROOF_MEMBRANE_SIDE,
    title:
      "From which side is the vapor barrier / airtightness membrane installed?",
    divider: true,
    nodes: [
      {
        id: "pr_3_1",
        title: "From the inside",
        value: "From the inside",
        nextSection: SECTION_ID.PITCHED_ROOF_BUILD_UP,
      },
      {
        id: "pr_3_2",
        title: "From the outside",
        value: "From the outside",
        nextSection: SECTION_ID.PITCHED_ROOF_BUILD_UP_OUTSIDE,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_BUILD_UP,
    divider: true,
    title: "Roof build-up:",
    nodes: [
      {
        id: "pr_4_1",
        title: "Diffusion-tight with wood layer (Sd >20 m)",
        value: "Diffusion-tight with wood layer (Sd >20 m)",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_tight,
        iconPng: pitched_roof_tight_png,
        // hoverElementPadding: "0px",
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS,
      },
      {
        id: "pr_4_3",
        title: "Diffusion-open with wood layer",
        value: "Diffusion-open with wood layer",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_tight,
        iconPng: pitched_roof_tight_png,
        // hoverElementPadding: "0px",
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS,
      },
      {
        id: "pr_4_2",
        title: "Open to diffusion with/without installation underlay",
        value: "Open to diffusion with/without installation underlay",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_open,
        // hoverElementPadding: "0px",
        iconPng: pitched_roof_open_png,
        end: true,
      },
    ],
    warning: [
      {
        message: detectFreeMessage,
        rule: "pr_4_2",
        pdf: customPdfForm,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
    title: "Which vapour control layer (VCL) do you want to use?",
    nr: 4,
    divider: true,
    // highlightNode: {
    //   top: "240px",
    //   left: "0px",
    //   width: "100%",
    //   height: "11px",
    // },
    visualNode: {
      img: sigaPitchedRoof,
      label: "4",
      top: "230px",
      left: "240px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_11_1",
        title: "Majrex",
        value: "Majrex",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
      },
      {
        id: "pr_11_2",
        title: "Majpell 5 / Majpell 5 R",
        value: "Majpell 5 / Majpell 5 R",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
      },
    ],
    warning: [
      {
        message:
          "In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer.",
        rule: "pr_11_2",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS,
    title:
      "Type of insulation material between the rafters (full rafter insulation):",
    divider: true,
    highlightNode: {
      top: "90px",
      left: "",
      width: "100%",
      height: "152px",
    },
    nr: 1,
    visualNode: {
      img: sigaPitchedRoof,
      label: "1",
      top: "140px",
      left: "120px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_5_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
      },
      {
        id: "pr_5_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
      },
      {
        id: "pr_5_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
      },
      { id: "pr_5_4", title: "Other", value: "Other", end: true },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_5_4",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES,
    title:
      "What material properties does the intermediate rafter insulation have?",
    // nextSection: SECTION_ID.WOODEN_SUPPORT_PANEL,
    highlightNode: {
      top: "90px",
      left: "",
      width: "100%",
      height: "152px",
    },
    nr: 1,
    visualNode: {
      img: sigaPitchedRoof,
      label: "1",
      top: "140px",
      left: "120px",
      imgTop: "10%",
    },
    multipleChoice: true,
    mustSelectAll: true,
    nextSection: SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
    nodes: [
      {
        id: "pr_6_1",
        title: "Insulation layer thickness (mm):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_6_1_1",
            title: "120 - 139",
            value: "120 - 139",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_2",
            title: "140 - 159",
            value: "140 - 159",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_3",
            title: "160 - 179",
            value: "160 - 179",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_4",
            title: "180 - 199",
            value: "180 - 199",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_5",
            title: "200 - 219",
            value: "200 - 219",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_6",
            title: "220 - 239",
            value: "220 - 239",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_7",
            title: "240 - 259",
            value: "240 - 259",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_8",
            title: "260 - 279",
            value: "260 - 279",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_9",
            title: "280 - 300",
            value: "280 - 300",
            parent: "pr_6_1",
          },
          {
            id: "pr_6_1_10",
            title: "other",
            value: "other",
            parent: "pr_6_1",
            end: true,
          },
        ],
      },
      {
        id: "pr_6_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_6_2_2",
            title: "0.036 - 0.040",
            value: "0.040",
            parent: "pr_6_2",
          },
          {
            id: "pr_6_2_3",
            title: "0.031 - 0.035",
            value: "0.035",
            parent: "pr_6_2",
          },
          {
            id: "pr_6_2_4",
            title: "0.025 - 0.030",
            value: "0.032",
            parent: "pr_6_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_6_1_10",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
    nr: 2,
    highlightNode: {
      top: "74px",
      left: "",
      width: "100%",
      height: "14px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "2",
      top: "60px",
      left: "140px",
      imgTop: "10%",
    },
    title: "What type of sealing is above the wooden formwork?",
    divider: true,
    nodes: [
      {
        id: "pr_7_1",
        title: "Bitumen",
        value: "Bitumen",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_BUILD_UP,
                nodeId: "pr_4_3",
              },
            ],
          },
        ],
      },
      {
        id: "pr_7_2",
        title: "Diffusible undercover",
        value: "Diffusible undercover",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
      },
      {
        id: "pr_7_3",
        title: "Other",
        value: "Other",
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_7_3",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
    title: "Which covering material is used?",
    nr: 3,
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "47px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "3",
      top: "0px",
      left: "220px",
      imgTop: "10%",
    },
    divider: true,
    nodes: [
      {
        id: "pr_8_1",
        title: "Brick/concrete roof tiles",
        value: "Brick/concrete roof tiles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
                nodeId: "pr_7_2",
              },
              {
                sectionId: SECTION_ID.PITCHED_ROOF_BUILD_UP,
                nodeId: "pr_4_1",
              },
            ],
          },
        ],
      },
      {
        id: "pr_8_2",
        title: "Metal roofing",
        value: "Metal roofing",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_BUILD_UP,
                nodeId: "pr_4_3",
              },
            ],
          },
        ],
      },
      {
        id: "pr_8_3",
        title: "Slate",
        value: "Slate",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
                nodeId: "pr_7_2",
              },
              {
                sectionId: SECTION_ID.PITCHED_ROOF_BUILD_UP,
                nodeId: "pr_4_1",
              },
            ],
          },
        ],
      },
      {
        id: "pr_8_4",
        title: "No additional covering (bare roof)",
        value: "No additional covering (bare roof)",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_SEALING_TYPE,
                nodeId: "pr_7_2",
              },
              {
                sectionId: SECTION_ID.PITCHED_ROOF_BUILD_UP,
                nodeId: "pr_4_1",
              },
            ],
          },
        ],
      },
      {
        id: "pr_8_5",
        title: "Bitumen membrane / shingles",
        value: "Bitumen membrane / shingles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_BUILD_UP,
                nodeId: "pr_4_3",
              },
            ],
          },
        ],
      },
      {
        id: "pr_8_6",
        title: "Other",
        value: "Other",
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_8_6",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_COVERING_COLOR,
    title: "Color of the covering?",
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "47px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "3",
      top: "0px",
      left: "220px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_9_1",
        title: "Dark (Black, anthracite)",
        value: "Dark (Black, anthracite)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
      },
      {
        id: "pr_9_2",
        title: "Light (White, beige)",
        value: "Light (White, beige)",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
        disableRules: [
          {
            condition: Condition.AND,
            rules: [
              {
                sectionId: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
                nodeId: "pr_8_3",
              },
              {
                sectionId: SECTION_ID.PITCHED_ROOF_COVERING_MATERIAL,
                nodeId: "pr_8_5",
              },
            ],
          },
        ],
      },
      {
        id: "pr_9_3",
        title: "Green, red & brown",
        value: "Green, red & brown",
        nextSection: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_SHADE_CAUSED_BY,
    title: "Shade is caused by",
    highlightNode: {
      top: "0px",
      left: "",
      width: "100%",
      height: "47px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "3",
      top: "0px",
      left: "220px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_10_1",
        title: "Solar panel",
        value: "Solar panel",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
      },
      {
        id: "pr_10_2",
        title: "Buildings / Trees",
        value: "Buildings / Trees",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
      },
      {
        id: "pr_10_3",
        title: "No shade",
        value: "No shade",
        nextSection: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_VAPOR_CONTROL_LAYER,
    title: "Which vapour control layer (VCL) do you want to use?",
    nr: 4,
    divider: true,
    // highlightNode: {
    //   top: "240px",
    //   left: "0px",
    //   width: "100%",
    //   height: "11px",
    // },
    visualNode: {
      img: sigaPitchedRoof,
      label: "4",
      top: "230px",
      left: "240px",
      imgTop: "10%",
    },
    nodes: [
      {
        id: "pr_11_1",
        title: "Majrex",
        value: "Majrex",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
      },
      {
        id: "pr_11_2",
        title: "Majpell 5 / Majpell 5 R",
        value: "Majpell 5 / Majpell 5 R",
        nextSection: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
      },
    ],
    warning: [
      {
        message:
          "In addition to the tried-and-tested SIGA adhesive products, the use of the Majrex 200 vapor control layer offers you even greater security.<br></br> With its hygrobrid technology and even more security, it fulfills the desire of many tradespeople to get everything from a single source. A large number of different applications can be covered with the universally applicable and easy-to-use vapor control layer.",
        rule: "pr_11_2",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ALIGNMENT,
    nr: 5,
    highlightNode: {
      top: "240px",
      left: "0px",
      width: "100%",
      height: "11px",
    },
    visualNode: {
      img: sigaPitchedRoof,
      label: "4",
      top: "230px",
      left: "240px",
      imgTop: "10%",
    },
    api: API.PITCHED_ROOF,
    paramsFetched: false,
    // visualNode: {
    //   img: sigaRoof,
    //   label: "5",
    //   top: "0px",
    //   left: "-40px",
    // },
    // highlightNode: {
    //   top: "6px",
    //   left: "",
    //   width: "100%",
    //   height: "20px",
    // },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    nextSection: SECTION_ID.FORM,
    nodes: angleNodes,
  },
];
