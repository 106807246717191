import styled from "styled-components";
import { MAIN_GREY, MEDIA_VERY_SMALL, TEXT_COLOR } from "../../constants";
import { ChoiceButton } from "../ChoiceButton";
import { VisualButton } from "../VisualButton";
import {
  deselectAtLevel,
  ErrorType,
  setNextSelection,
  setSelectionAtCurrentLevel,
} from "../../reducers/appData";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useRef } from "react";
import { SelectComponent } from "../SelectComponent";
import { NumberCircle } from "../NumberCircle";
import { RadioButton } from "../RadioButton";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { SupportedLanguage } from "../../App";
import { sanitizeWarningMessage } from "../../utils";
import { WarningContainer } from "../../styledComponents";
import scrollFunction from "../../utils/scrollFunction";
import { TestTable } from "../TestTable";
import {
  BUTTON_TYPE,
  Condition,
  DataNode,
  WarningMessage,
} from "../../data/types";
import { notSupportedMessage } from "../../data/constants";

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CoverImageContainer = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin: 50px 0;
  img {
    width: 100%;
  }
`;

const StepTitle = styled.h3`
  flex: 0 0 100%;
  color: ${TEXT_COLOR};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
`;

const FormLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
  .MuiFormLabel-root {
    color: ${TEXT_COLOR};
    margin-bottom: 16px;
  }
  .MuiFormControl-root {
    flex: 0 0 calc((100% - 64px) / 2);
    display: inline-flex;
    &:first-of-type {
      display: inline-flex;
      margin-right: 32px;
    }
  }
`;

const ContentContainer = styled.div.attrs((props: { hasNr: boolean }) => props)`
  ${(props) =>
    props.hasNr ? `flex: 0 0 calc(100% - 77px);` : `flex: 0 0 100%;`}
  ${MEDIA_VERY_SMALL} {
    flex: 0 0 100%;
  }
`;

const NumberContainer = styled.div`
  display: inline-flex;
  width: 44px;
  margin-right: 32px;
  ${MEDIA_VERY_SMALL} {
    display: flex;
  }
`;

const StepInnerContainer = styled.div.attrs(
  (props: { hasDivider: boolean }) => props
)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${(props) =>
    props.hasDivider &&
    `
    margin-top: 28px; 
    padding-top: 28px; 
    border-top: 1px solid ${MAIN_GREY}
  `}
`;

export interface ButtonProps {
  title: string;
  selected?: boolean;
  buttonType?: BUTTON_TYPE;
  icon?: string;
  tooltip?: string;
}

interface StepProps {
  title?: string;
  warning?: WarningMessage[];
  divider?: boolean;
  theNr?: any;
  level: number;
  parents?: DataNode[];
  nodes?: DataNode[];
  hidden: boolean;
  coverImage?: string;
  fetchParams?: () => void;
}

const Step: React.FC<StepProps> = ({
  title,
  theNr,
  nodes,
  divider,
  level,
  hidden,
  warning,
  coverImage,
  fetchParams,
}) => {
  const stepRef = useRef(null);
  const intl = useIntl();
  const { lang } = useParams();
  const dispatch = useAppDispatch();
  const currentLevel = useAppSelector((state) => state.appData.currentLevel);
  const skipSteps = useAppSelector((state) => state.appData.skipSteps);
  const allData = useAppSelector((state) => state.appData.data);
  const error = useAppSelector((state) => state.appData.parameterFetchError);
  const parametersFetching = useAppSelector(
    (state) => state.appData.parametersFetching
  );
  const onSelect = (id: string, value: string) => {
    // dispatch(setSelectionAtCurrentLevel({ id, level, value }));
    dispatch(setNextSelection({ id, level, value }));
  };

  const isDataFetchingLevel = () =>
    level === currentLevel && !!allData[currentLevel]?.api;
  const dataFetchError = isDataFetchingLevel() && error !== undefined;

  const deselect = () => {
    dispatch(deselectAtLevel({ level }));
  };
  const skipped = skipSteps.find(
    (st) =>
      st.steps.includes(level) &&
      !!Object.keys(allData).find(
        (k: string) =>
          !!allData[parseInt(k, 10)].nodes.find(
            (n) =>
              (n.value === st.rule || n.selectValue === st.rule) &&
              n.selected &&
              parseInt(k, 10) === st.level
          )
      )
  );
  useEffect(() => {
    if (level === currentLevel && level !== 1) {
      scrollFunction((stepRef?.current as any).offsetTop);
    }
  }, [currentLevel, level]);
  return (
    <StepContainer
      className={`panel ${hidden || skipped ? "hidden" : "open"}`}
      ref={stepRef}
    >
      <StepInnerContainer hasDivider={divider}>
        {theNr && (
          <NumberContainer>
            <NumberCircle
              nr={theNr as number}
              isPulsing={level === currentLevel}
              red={level === currentLevel}
            />
          </NumberContainer>
        )}
        <ContentContainer hasNr={theNr}>
          {title && (
            <StepTitle>
              {intl.formatMessage({ id: title, defaultMessage: title })}
              {/* isDataFetchingLevel() && !parametersFetching && <TestTable /> */}
            </StepTitle>
          )}
          {coverImage && (
            <CoverImageContainer>
              <img src={coverImage} />
            </CoverImageContainer>
          )}
          {dataFetchError &&
            (error && error === ErrorType.FAILED_REQUEST ? (
              <WarningContainer className={`warning-message open`}>
                {intl.formatMessage({
                  id: `Failed to fetch data.`,
                  defaultMessage: `Failed to fetch data.`,
                })}
                <a
                  href="#"
                  style={{ paddingLeft: "5px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    fetchParams?.();
                  }}
                >
                  {intl.formatMessage({
                    id: `Please try again`,
                    defaultMessage: `Please try again`,
                  })}
                </a>
              </WarningContainer>
            ) : (
              <WarningContainer
                className={`warning-message open`}
                dangerouslySetInnerHTML={{
                  __html: sanitizeWarningMessage(
                    notSupportedMessage,
                    lang as SupportedLanguage,
                    intl
                  ),
                }}
              />
            ))}
          <FormLayout>
            {nodes?.map((b, i) => {
              const _disabled =
                b.disabled ||
                (!!b.disableRules &&
                  b.disableRules.some((r) => {
                    if (r.condition === Condition.AND) {
                      return r.rules.every(
                        (rr) =>
                          !!allData
                            .find((e) => e.id === rr?.sectionId)
                            ?.nodes.find(
                              (n) =>
                                (n.id === rr?.nodeId && n.selected) ||
                                (n.value === rr?.nodeId && n.selected)
                            )
                      );
                    } else if (r.condition === Condition.OR) {
                      return r.rules.some(
                        (rr) =>
                          !!allData
                            .find((e) => e.id === rr?.sectionId)
                            ?.nodes.find(
                              (n) =>
                                (n.id === rr?.nodeId && n.selected) ||
                                (n.value === rr?.nodeId && n.selected)
                            )
                      );
                    } else {
                      return false;
                    }
                  }));
              switch (b.buttonType) {
                case BUTTON_TYPE.SQUARE:
                  return (
                    <ChoiceButton
                      key={i}
                      title={b.title}
                      selected={b.selected ?? false}
                      icon={b.icon}
                      square={1}
                      isLoading={isDataFetchingLevel() && parametersFetching}
                      onClick={() => onSelect(b.id, b.value)}
                      tooltip={b.tooltip}
                      disabled={_disabled}
                    />
                  );
                case BUTTON_TYPE.VISUAL:
                  return (
                    <VisualButton
                      key={i}
                      title={b.title}
                      selected={b.selected ?? false}
                      icon={b.icon}
                      iconPng={b.iconPng}
                      onClick={() => onSelect(b.id, b.value)}
                      disabled={_disabled}
                      hoverElementPadding={b.hoverElementPadding}
                    />
                  );
                case BUTTON_TYPE.CHECKBOX:
                  return (
                    <SelectComponent
                      key={i}
                      title={b.title}
                      id={b.id}
                      isLoading={isDataFetchingLevel() && parametersFetching}
                      disabled={
                        _disabled ||
                        (isDataFetchingLevel() && dataFetchError) ||
                        false
                      }
                      childNodes={b.childNodes}
                      value={b.value}
                      onSelect={(id, value) => onSelect(id, value)}
                      shouldShowDisabledMessage={isDataFetchingLevel()}
                    />
                  );
                case BUTTON_TYPE.RADIO:
                  return (
                    <RadioButton
                      id={b.id}
                      disabled={_disabled}
                      key={i}
                      title={b.title}
                      selected={b.selected ?? false}
                      onChange={() => onSelect(b.id, b.value)}
                      deselect={() => deselect()}
                      freeType={b.freeType}
                      level={level}
                    />
                  );
                default:
                  return (
                    <ChoiceButton
                      key={i}
                      square={0}
                      title={b.title}
                      selected={b.selected ?? false}
                      icon={b.icon}
                      isLoading={isDataFetchingLevel() && parametersFetching}
                      tooltip={b.tooltip}
                      onClick={() => onSelect(b.id, b.value)}
                      disabled={_disabled}
                    />
                  );
              }
            })}
          </FormLayout>
        </ContentContainer>
        {warning &&
          warning.map((wMsg, i) => (
            <WarningContainer
              key={i}
              className={`warning-message ${
                nodes?.find(
                  (n) =>
                    (n.id === wMsg.rule || n.value === wMsg.rule) && n.selected
                )
                  ? "open"
                  : "hidden"
              }`}
              dangerouslySetInnerHTML={{
                __html: sanitizeWarningMessage(
                  wMsg.message,
                  lang as SupportedLanguage,
                  intl,
                  wMsg.pdf || undefined
                ),
              }}
            />
          ))}
      </StepInnerContainer>
    </StepContainer>
  );
};

export default Step;
