import { IntlShape } from "react-intl";
import { SupportedLanguage } from "../App";
import dompurify from "dompurify";
import { pdfForm } from "../data/constants";

const sanitizer = dompurify.sanitize;

export const sanitizeWarningMessage = (
  str: string,
  lang: string,
  intl: IntlShape,
  customPdfForm?: { [key: string]: string }
) =>
  sanitizer(
    intl
      .formatMessage({
        id: str,
        defaultMessage: str,
      })
      .replace(
        "{link}",
        `<a href="${process.env.REACT_APP_BACKEND_HOST}/public/${
          customPdfForm
            ? customPdfForm[lang as SupportedLanguage]
            : pdfForm[lang as SupportedLanguage]
        }" target="_blank">`
      )
      .replace("{linkEnd}", "</a>"),
    { ADD_ATTR: ["target"] }
  );
