import { IntlShape } from "react-intl";

export const textProductReplacement = ({
  text,
  product,
  intl,
  type,
}: {
  text: string;
  product: string;
  intl?: IntlShape;
  type?: string;
}): string => {
  let newStr = text;
  newStr = text.replaceAll("{{p}}", product);
  if (type) {
    newStr = newStr.replaceAll("{{t}}", type);
  }
  if (intl) {
    const flatroof_msg =
      "There must be no additional airtight layer between the vapor barrier and the insulation between the rafters on the construction side.";
    newStr = newStr.replaceAll(
      "{{flatroof_msg}}",
      intl.formatMessage({
        id: flatroof_msg,
        defaultMessage: flatroof_msg,
      })
    );
  }
  return newStr.replaceAll("\n", "</p><p>");
};
